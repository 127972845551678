import React from 'react';
import { Modal, Image } from 'react-bootstrap';
import Logo from "src/assets/images/logo.svg";
import useCheckProcessedStatus from 'src/hooks/useCheckProcessedStatus';

const Processing = () => {
    useCheckProcessedStatus();  // Call the custom hook to start processing status checks

    return (
        <Modal show={true} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    <Image src={Logo} alt="Logo" style={{ width: '50px' }} />
                    Processing Account
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <h4>Your account is being processed...</h4>
                <p>Please try again after some time. Thank you for your patience.</p>
            </Modal.Body>
        </Modal>
    );
};

export default Processing;
